/* eslint-disable react/jsx-pascal-case */
import Header from "../components/Header";
import SidebarNav from "../components/SidebarNav";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import User from "../components/User/User";
import Adduser from "../components/User/Adduser";
import Dashboard from "../components/Dashboard/Dashboard";
import Transaction from "../components/Transaction";
import PaymentLink from "../components/PaymentLink";
import PGTransfer from "../components/PGTransfer";
import WalletDebit from "../components/WalletDebit";
import Profile from "../components/Profile";
import Payment from "../components/Payment";
import Payout from "../components/Payout";
import Charges from "../components/User/Charges";
import EditUser from "../components/User/Edituser";
import View from "../components/View";
import Distributor from "../components/Distributor/Distributor";
import Adddistributor from "../components/Distributor/Adddistributor";
import Editdistributor from "../components/Distributor/Editdistributor";
import DistributorCharges from "../components/Distributor/DistributorCharges";
import Dis_payout from "../components/Dis_payout";
import Dis_transaction from "../components/Dis_transaction";
import WalletCredit from "../components/WalletCredit";
import Adminpayout from "../components/Adminpayout";
import AdminTransaction from "../components/AdminTransaction";
import Wallet_Credit_Trans from "../components/Wallet_Credit_Trans";
import Wallet_Debit_Trans from "../components/Wallet_Debit_Trans";
import PGTransfer_Trans from "../components/PGTransfer_Trans";
import Paymentcheck from "../components/Paymentcheck";

const Layout = () => {
  return (
    <div className="">
      <div className="App overflow-y-hidden ">
        <Header />
        <div className="w-full min-h-[90vh] grid grid-cols-12 ">
          <div className="col-span-2 py-4 px-2 border-r">
            <SidebarNav />
          </div>
          <div className="col-span-10 h-screen px-5 ">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/user" element={<User />} />
                <Route path="/adduser" element={<Adduser />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/paymentlink" element={<PaymentLink />} />
                <Route path="/pgtransfer" element={<PGTransfer />} />
                <Route path="/wallet" element={<WalletDebit />} />
                <Route path="/wallet_credit" element={<WalletCredit />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/Payout" element={<Payout />} />
                <Route path="/charges" element={<Charges />} />
                <Route path="/editUser" element={<EditUser />} />
                <Route path="/view" element={<View />} />
                <Route path="/distributor" element={<Distributor />} />
                <Route path="/adddistributor" element={<Adddistributor />} />
                <Route path="/editdistributor" element={<Editdistributor />} />
                <Route
                  path="/chargesdistributor"
                  element={<DistributorCharges />}
                />
                <Route path="/distributor_payout" element={<Dis_payout />} />
                <Route
                  path="/distributor_transaction"
                  element={<Dis_transaction />}
                />
                <Route
                  path="/admintransaction"
                  element={<AdminTransaction />}
                />

                <Route path="/adminpayout" element={<Adminpayout />} />
                <Route
                  path="/wallet_credit_transaction"
                  element={<Wallet_Credit_Trans />}
                />
                <Route
                  path="/wallet_debit_transaction"
                  element={<Wallet_Debit_Trans />}
                />
                <Route
                  path="/pgTransfer_trans"
                  element={<PGTransfer_Trans />}
                />
                <Route path="/paymentcheck" element={<Paymentcheck />} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
