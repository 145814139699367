import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkpasswordUser, updatepasswordUser } from "../Api/Admin";
import { viewByidUser } from "../Api/Admin";

const Passwordchange = () => {
  const [inputs, setInputs] = useState({
    email: "",
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [userdata, setuserdata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    var userid = sessionStorage.getItem("user_id");
    var viewuser = await viewByidUser({ id: userid });
    setuserdata(viewuser);
  };
  const submitbtn = async () => {
    if (inputs.oldpassword.length === 0) {
      toast.error("Old Password Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (inputs.newpassword.length === 0) {
      toast.error("New Password Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (inputs.confirmpassword.length === 0) {
      toast.error("Confirm Password Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var checkpassword = await checkpasswordUser({
        email: userdata[0].email,
        password: inputs.oldpassword,
      });
      if (checkpassword === true) {
        if (inputs.newpassword === inputs.confirmpassword) {
          var data = {
            id: userdata[0].id,
            password: inputs.newpassword,
          };
          var updatepass = await updatepasswordUser(data);
          if (updatepass.message === "Updated Successfully") {
            toast.success("New Password Updated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("New Password & Confirm Password Not match...", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        toast.error("Old Password Not match...", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  return (
    <>
      <h1 className="text-start font-bold text-xl mt-5">Change Password</h1>
      <div className="py-5 pl-5">
        <Stack spacing={1} direction={"column"}>
          <label className="text-left font-bold">Old Password </label>
          <input
            class="border border-black p-2 rounded-lg outline-none w-1/3"
            placeholder="Enter Your Old Password"
            alt=""
            name="oldpassword"
            defaultValue={inputs.oldpassword}
            onChange={handleChange}
          ></input>

          <label className="text-left font-bold">New Password </label>
          <input
            class="border border-black p-2 rounded-lg outline-none w-1/3"
            placeholder="Enter your New password"
            alt=""
            name="newpassword"
            defaultValue={inputs.newpassword}
            onChange={handleChange}
          ></input>

          <label className="text-left font-bold">Confirm New Password</label>
          <input
            class="border border-black p-2 rounded-lg outline-none w-1/3"
            placeholder="Enter your Confirm New Password"
            alt=""
            name="confirmpassword"
            defaultValue={inputs.confirmpassword}
            onChange={handleChange}
          ></input>
        </Stack>
      </div>

      <div className="flex justify-start py-2 pl-5">
        <button
          className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
          onClick={submitbtn}
        >
          Change Password
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default Passwordchange;
