/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alluserdata, destroyuserdata, updateuserdata } from "../../Api/User";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllDistributordata } from "../../Api/distributor";

const User = () => {
  const [userdata, setuserdata] = useState([]);
  const [distributordata, setdistributordata] = useState([]);
  const [alluserdata, setalluserdata] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var userdata = await Alluserdata();
    var alldiscridata = await AllDistributordata();
    setuserdata(userdata);
    setalluserdata(userdata);
    setdistributordata(alldiscridata);
  };
  const changestatus = async (data) => {
    var status = document.getElementById(`status${data.id}`).value;
    if (status.length !== 0) {
      var newdata = {
        id: data.id,
        status: status,
      };
      var updatedata = await updateuserdata(newdata);
      if (updatedata === "Updated Successfully") {
        toast.success("User Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getalldata();
      }
    } else {
      toast.error("Please Select Other Option...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  const searchitem = async (e) => {
    if (e.length !== 0) {
      if (alluserdata.length !== 0) {
        var filterdata = [];
        for (var i = 0; i < alluserdata.length; i++) {
          if (
            alluserdata[i].name.toLowerCase().match(e.toLowerCase()) ||
            alluserdata[i].merchant_ID.toLowerCase().match(e.toLowerCase())
          ) {
            filterdata.push(alluserdata[i]);
          }
        }
        setuserdata(filterdata);
      }
    } else {
      setuserdata(alluserdata);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = userdata.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userdata.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const deletebtn = async (e) => {
    await destroyuserdata({ id: e });
    window.location.reload();
  };
  const editbtn = (e) => {
    sessionStorage.setItem("edituser", e);
    window.location.replace(`/editUser`);
  };
  const vewcharges = (e) => {
    sessionStorage.setItem("edituser", e);
    window.location.replace(`/charges`);
  };
  const approvebtn = async (id) => {
    var newdata = {
      id: id,
      status: "Active",
    };
    var updatedata = await updateuserdata(newdata);
    if (updatedata === "Updated Successfully") {
      toast.success("User Updated Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      getalldata();
    }
  };
  const selectdistributor = (e) => {
    if (e.length !== 0) {
      if (e === "null") {
        setuserdata(alluserdata);
      } else {
        if (alluserdata.length !== 0) {
          var filterdata = [];
          for (var i = 0; i < alluserdata.length; i++) {
            if (alluserdata[i].dis_id == e) {
              filterdata.push(alluserdata[i]);
            }
          }
          setuserdata(filterdata);
        }
      }
    } else {
      setuserdata(alluserdata);
    }
  };
  return (
    <div>
      <div className="flex justify-between py-4">
        <h1 className="text-start font-bold text-xl">Users List</h1>
        <Button
          variant="contained"
          size="small"
          color="info"
          onClick={() => window.location.replace("/adduser")}
        >
          Add User
        </Button>
      </div>
      <div className="flex justify-between py-4">
        <input
          placeholder="Seach User Name or Merchant ID"
          className="border p-2 w-1/3 border-black rounded"
          onChange={(e) => {
            searchitem(e.target.value);
          }}
        />
      </div>
      <div className="flex justify-between py-4">
        <div className="w-[25rem]">
          <label variant="subtitle2" className="flex py-1 font-bold">
            Select Distributor
          </label>
          <select
            className="border p-2 border-black rounded w-full"
            onChange={(e) => {
              selectdistributor(e.target.value);
            }}
          >
            <option className="uppercase font-bold" value="null">
              Select
            </option>
            {distributordata.length !== 0
              ? distributordata.map((data, index) => (
                  <option
                    key={index}
                    className="uppercase font-bold"
                    value={data.id}
                  >
                    {data.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div></div>
      </div>

      <div className="my-8" style={{ height: 700, width: "100%" }}>
        <TableContainer component={Paper} className="border border-black">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-black text-white">
              <TableRow className="border border-black">
                <TableCell>
                  {" "}
                  <text className="font-bold text-white ">#</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">Name</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">
                    Merchant ID
                  </text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">
                    Company Name
                  </text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">Email</text>
                </TableCell>

                <TableCell align="left">
                  <text className="font-bold text-white text-xs">Balance</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">Status</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white text-xs">
                    Status Action
                  </text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs">Charge</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs">Edit</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs">Remove</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs">Approve</text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-2">
              {currentPost.length !== 0
                ? currentPost.map((data, index) => (
                    <TableRow className="border border-black" key={index}>
                      <TableCell className="border border-black">
                        <p> {index + 1}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <p className="text-xs"> {data.name}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <p className="text-xs"> {data.merchant_ID}</p>
                      </TableCell>

                      <TableCell className="border border-black" align="left">
                        <p className="text-xs">{data.companyname}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <p className="text-xs">{data.email}</p>
                      </TableCell>

                      <TableCell className="border border-black" align="left">
                        <p className="text-xs">
                          {parseFloat(data.balance).toFixed(2)}
                        </p>
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <p className="text-xs">{data.status}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <select
                          onChange={() => {
                            changestatus(data);
                          }}
                          className="text-xs"
                          id={`status${data.id}`}
                        >
                          <option value="">Select</option>
                          <option value="De-Active">De-Active</option>
                          <option value="Block">Block</option>
                          <option value="Active">Active</option>
                        </select>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <button
                          className="text-xs bg-green-800 text-white p-2 rounded"
                          onClick={() => {
                            vewcharges(data.id);
                          }}
                        >
                          Charge
                        </button>
                      </TableCell>
                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="left"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="m-2 items-start "
                          color="primary"
                          onClick={() => {
                            editbtn(data.id);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="left"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="p-2 items-start "
                          color="error"
                          onClick={() => {
                            deletebtn(data.id);
                          }}
                        >
                          remove
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {data.status === "In-Active" ? (
                          <Button
                            variant="contained"
                            size="small"
                            className=" items-start "
                            color="info"
                            onClick={() => {
                              approvebtn(data.id);
                            }}
                          >
                            Approve
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            disabled
                            className=" items-start "
                            color="info"
                            onClick={() => {
                              approvebtn(data.id);
                            }}
                          >
                            Approve
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {pageNumbers.length > 1 ? (
          <div className="row mt-5">
            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={() => setCurrentPage(number)}
                    className="page-link"
                  >
                    {number}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
};

export default User;
