import { Admin } from "../axios";

export const LoginUser = async (data) => {
  var LoginUser = await Admin.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return LoginUser;
};

export const viewByidUser = async (data) => {
  var viewByidUser = await Admin.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidUser;
};

export const checkpasswordUser = async (data) => {
  var checkpasswordUser = await Admin.post(`/checkpassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkpasswordUser;
};

export const updatepasswordUser = async (data) => {
  var updatepasswordUser = await Admin.post(`/updatepassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatepasswordUser;
};
