import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAqrxq7Hr-6kMSPBvMt1BirByK0qUCPtwA",
  authDomain: "mespay-4d9e4.firebaseapp.com",
  projectId: "mespay-4d9e4",
  storageBucket: "mespay-4d9e4.appspot.com",
  messagingSenderId: "801327477163",
  appId: "1:801327477163:web:fe2ac1d94f721f58e962ee",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}
export { firebase };
