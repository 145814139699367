import React, { useState } from "react";
import { Stack } from "@mui/material";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Registersuccess } from "../../Api/smtp";
import { CreteDistributor } from "../../Api/distributor";

const Adddistributor = () => {
  const [userdata, setuserdata] = useState({
    name: "",
    email: "",
    phone: "",
    pan_no: "",
    aadhar_no: "",
    address_url: "",
    id_url: "",
    address: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [clicked, setclicked] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setuserdata((values) => ({ ...values, [name]: value }));
  };
  const getaddressurl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("logo/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl1 = await file13;
    if (imgurl1 !== null) {
      toast.success("Image uploaded...", {
        autoClose: 5000,
        transition: Slide,
      });
      setuserdata((values) => ({ ...values, address_url: imgurl1 }));
    }
  };
  const getidurl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("logo/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl1 = await file13;
    if (imgurl1 !== null) {
      toast.success("Image uploaded...", {
        autoClose: 5000,
        transition: Slide,
      });
      setuserdata((values) => ({ ...values, id_url: imgurl1 }));
    }
  };
  const submitbtn = async () => {
    const newerrors = {};

    if (!userdata.email) {
      newerrors.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userdata.email)
    ) {
      newerrors.email = "Invalid email address";
    }
    if (!userdata.name) {
      newerrors.name = "Name Required";
    }
    if (!userdata.phone) {
      newerrors.phone = "Phone Number Required";
    }
    if (!userdata.pan_no) {
      newerrors.pan_no = "Pan Number Required";
    }
    if (!userdata.aadhar_no) {
      newerrors.aadhar_no = "Aadhar Number Required";
    }

    if (!userdata.address) {
      newerrors.address = "Address Required";
    }
    if (!userdata.address_url) {
      newerrors.address_url = "Address Proof Required";
    }
    if (!userdata.id_url) {
      newerrors.id_url = "Id Proof Required";
    }

    seterrorlist(newerrors);
    setclicked(false);
    if (Object.keys(newerrors).length === 0) {
      setclicked(true);
      userdata["password"] = "Mespay@2023";
      userdata["balance"] = "0";
      userdata["pg_balance"] = "0";
      userdata["status"] = "Active";
      userdata["charge"] = "1.25";
      var createuser = await CreteDistributor(userdata);
      if (createuser.message === "SUCCESS") {
        await Registersuccess({
          email: userdata.email,
          name: userdata.name,
          password: "Mespay@2023",
        });
        toast.success("Distributor Created Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.replace("/distributor");
        }, 2000);
      } else {
        toast.error(createuser.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between pt-4">
        <h1 className="text-start font-bold text-xl">Add New Distributor</h1>
      </div>
      <div className="pt-2">
        <Stack spacing={0.6} direction={"column"} className="  ">
          <label className="text-left font-semibold">Name </label>
          <input
            type="text"
            size="medium"
            placeholder="Name"
            name="name"
            className="w-96 border border-gray-600 p-2 rounded"
            onChange={handleChange}
            defaultValue={userdata.name}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.name}</div>
          ) : null}

          <label className="text-left font-semibold">Email </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.email}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.email}</div>
          ) : null}
          <label className="text-left font-semibold">Phone </label>
          <input
            type="number"
            placeholder="Phone"
            name="phone"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.phone}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.phone}</div>
          ) : null}
          <label className="text-left font-semibold">PAN NO</label>
          <input
            type="text"
            placeholder="PAN NO"
            name="pan_no"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.pan_no}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.pan_no}</div>
          ) : null}
          <label className="text-left font-semibold">Aadhar NO</label>
          <input
            type="text"
            name="aadhar_no"
            placeholder="Aadhar NO"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.aadhar_no}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.aadhar_no}</div>
          ) : null}

          <label className="text-left font-semibold">Address Proof</label>
          <input
            type="file"
            name="address_url"
            onChange={getaddressurl}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.address_url}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">
              {errorlist.address_url}
            </div>
          ) : null}
          <label className="text-left font-semibold">Id Proof</label>
          <input
            type="file"
            name="id_url"
            onChange={getidurl}
            className="w-96 border border-gray-600 p-2 rounded"
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.id_url}</div>
          ) : null}
          <label className="text-left font-semibold">Address </label>
          <textarea
            type="text"
            name="address"
            placeholder="Address"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.address}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.address}</div>
          ) : null}
        </Stack>
      </div>

      <div className="flex justify-start mt-5 mb-5 w-24">
        <Stack spacing={2}>
          {clicked === true ? (
            <button className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3">
              Please Wait...
            </button>
          ) : (
            <button
              className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
              onClick={submitbtn}
            >
              Submit
            </button>
          )}
        </Stack>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Adddistributor;
