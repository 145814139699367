import { Pgtransfer } from "../axios";

export const CretePgtransfer = async (data) => {
  var CretePgtransfer = await Pgtransfer.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePgtransfer;
};

export const viewByUserPgtransfer = async (data) => {
  var viewByUserPgtransfer = await Pgtransfer.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserPgtransfer;
};

export const viewByDatePgtransfer = async (data) => {
  var viewByDatePgtransfer = await Pgtransfer.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDatePgtransfer;
};

export const viewAllPgtransfer = async (data) => {
  var viewAllPgtransfer = await Pgtransfer.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllPgtransfer;
};
