/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Logo from "../assets/logo.jpg";
import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  LinkIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { BiChevronDown } from "react-icons/bi";
import { CgLogOff } from "react-icons/cg";
import { BiUserCircle } from "react-icons/bi";
import { MdTransferWithinAStation, MdWallet } from "react-icons/md";
import { HiTerminal } from "react-icons/hi";
import { FaWallet } from "react-icons/fa";

const SidebarNav = () => {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [profile, setprofile] = useState(false);
  const [pgtransfer, setpgtransfer] = useState(false);
  const [credit, setcredit] = useState(false);
  const [debit, setdebit] = useState(false);

  const logoutbtn = () => {
    sessionStorage.removeItem("user_id");
    window.location.reload();
  };
  return (
    <>
      <div className="flex flex-col items-center h-screen relative">
        <div className="pb-2">
          <img src={Logo} className="w-24" alt="" />
        </div>
        <ul className="flex flex-col font-medium gap-3">
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/")}
          >
            <RectangleStackIcon className="h-5 w-5" />
            Dashboard
          </li>
          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <BiUserCircle className="h-5 w-5" />
              User
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setToggle(!toggle)}
              />
            </div>
          </li>
          {toggle && (
            <ul className="top-5 cursor-pointer ml-7">
              <li
                className="text-left"
                onClick={() => window.location.replace("/adduser")}
              >
                Add User
              </li>
              <li
                className="text-left mt-4"
                onClick={() => window.location.replace("/user")}
              >
                Users
              </li>
            </ul>
          )}
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/distributor")}
          >
            <RectangleStackIcon className="h-5 w-5" />
            Distributor
          </li>
          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <LinkIcon className="h-5 w-5" />
              Payment Link
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setOpen(!open)}
              />
            </div>
          </li>
          {open && (
            <ul className="top-0 cursor-pointer ml-7">
              <li
                className="text-left pb-1"
                onClick={() => window.location.replace("/paymentlink")}
              >
                Payment Link
              </li>
              <li
                className="text-left pt-3"
                onClick={() => window.location.replace("/adminpayout")}
              >
                Admin Payout
              </li>
              <li
                className="text-left pt-3"
                onClick={() => window.location.replace("/Payout")}
              >
                Retailer Payout
              </li>
              <li
                className="text-left pt-3"
                onClick={() => window.location.replace("/distributor_payout")}
              >
                Distributor Payout
              </li>
            </ul>
          )}
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/transaction")}
          >
            <ArrowsRightLeftIcon className="h-5 w-5" />
            Retailer Transactions
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/distributor_transaction")}
          >
            <ArrowsRightLeftIcon className="h-5 w-5" />
            Distributor Transactions
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/admintransaction")}
          >
            <ArrowsRightLeftIcon className="h-5 w-5" />
            Admin Transactions
          </li>
          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <HiTerminal className="h-5 w-5" />
              PG Transfer
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setpgtransfer(!pgtransfer)}
              />
            </div>
          </li>
          {pgtransfer && (
            <ul className="top-5 cursor-pointer ml-7">
              <li
                className="text-left text-sm"
                onClick={() => window.location.replace("/pgtransfer")}
              >
                PG Transfer
              </li>
              <li
                className="text-left text-sm mt-2"
                onClick={() => window.location.replace("/pgTransfer_trans")}
              >
                PG Transfer Transaction
              </li>
            </ul>
          )}

          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <FaWallet className="h-5 w-5" />
              Wallet Debit
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setdebit(!debit)}
              />
            </div>
          </li>
          {debit && (
            <ul className="top-5 cursor-pointer ml-7">
              <li
                className="text-left text-sm"
                onClick={() => window.location.replace("/wallet")}
              >
                Wallet Debit
              </li>
              <li
                className="text-left text-sm mt-2"
                onClick={() =>
                  window.location.replace("/wallet_debit_transaction")
                }
              >
                Wallet Debit Transaction
              </li>
            </ul>
          )}

          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <MdWallet className="h-5 w-5" />
              Wallet Credit
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setcredit(!credit)}
              />
            </div>
          </li>
          {credit && (
            <ul className="top-5 cursor-pointer ml-7">
              <li
                className="text-left text-sm"
                onClick={() => window.location.replace("/wallet_credit")}
              >
                Wallet Credit
              </li>
              <li
                className="text-left text-sm mt-2"
                onClick={() =>
                  window.location.replace("/wallet_credit_transaction")
                }
              >
                Wallet Credit Transaction
              </li>
            </ul>
          )}

          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <UserIcon className="h-5 w-5" />
              Profile
            </div>
            <div className="items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setprofile(!profile)}
              />
            </div>
          </li>
          {profile && (
            <ul className="top-0 cursor-pointer ml-7">
              <li
                className="text-left"
                onClick={() => window.location.replace("/profile")}
              >
                Change Password
              </li>
            </ul>
          )}
          <li className="flex gap-2 cursor-pointer" onClick={logoutbtn}>
            <CgLogOff className="h-5 w-5" />
            Log out
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarNav;
