/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusPayout, transferPayout, verifyPayout } from "../Api/payout";
import { CreteToken } from "../Api/order";
import { BsFillTrash2Fill, BsPencilSquare } from "react-icons/bs";
import {
  CreteDistributor_payout,
  destroyDistributor_payout,
  updateDistributor_payout,
  viewAllDistributor_payout,
} from "../Api/distributor_payout";
import { CreteDistributor_transaction } from "../Api/distributor_transaction";
import { updateDistributordata, viewByidDistributor } from "../Api/distributor";
import { AiOutlineSearch } from "react-icons/ai";
import { createHistory } from "../Api/History";

const Dis_payout = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [allpayoutlist, setallpayoutlist] = useState([]);
  const [payoutdatalist, setpayoutdatalist] = useState([]);
  const [singledata, setsingledata] = useState([]);
  const [viewmodalIsOpen, setviewmodalIsOpen] = useState(false);
  const [inputid, setinputid] = useState(null);
  const [updateid, setupdateid] = useState(null);
  const [updatestatus, setupdatestatus] = useState(false);
  const [clicked, setclicked] = useState(false);

  const additembtn = () => {
    setIsOpen(true);
    setupdatestatus(false);
    setupdateid(null);
    setpayoutdata({
      account: "",
      amount: "",
      ifsc: "",
      name: "",
    });
  };
  const closeModal = () => {
    setIsOpen(false);
    setpayoutdata({
      account: "",
      amount: "",
      ifsc: "",
      name: "",
    });
  };
  const closeModalnew = () => {
    setviewmodalIsOpen(false);
  };
  const [payoutdata, setpayoutdata] = useState({
    account: "0",
    amount: "",
    ifsc: "",
    name: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayoutdata((values) => ({ ...values, [name]: value }));
  };
  const addpayout = async () => {
    if (payoutdata.account.length === 0) {
      toast.error("Account Number Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (payoutdata.ifsc.length === 0) {
      toast.error("Ifsc Code Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (payoutdata.name.length === 0) {
      toast.error("Account Holder Name Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      if (payoutdatalist.length < 1) {
        var userid = sessionStorage.getItem("user_id");
        payoutdata["status"] = "Pending";
        payoutdata["amount"] = "0";
        payoutdata["user_id"] = userid;
        var craetepayout = await CreteDistributor_payout(payoutdata);
        if (craetepayout.message === "SUCCESS") {
          toast.success("Payout Created Successfully...", {
            autoClose: 2000,
            transition: Slide,
          });
          setIsOpen(false);
          getpayoutdata();
        }
      } else {
        toast.error("There should be only one payout account...", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  useEffect(() => {
    getpayoutdata();
  }, []);

  const getpayoutdata = async () => {
    var userid = sessionStorage.getItem("user_id");
    var alldata = await viewAllDistributor_payout();
    if (alldata.length !== 0) {
      var filterdata = await alldata.filter((data) => {
        return data.status === "Pending";
      });
      setpayoutdatalist(filterdata);
      setallpayoutlist(filterdata);
    } else {
      setpayoutdatalist([]);
      setallpayoutlist([]);
    }
  };
  function NumInWords(number) {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }
  const transferbtn = async (data) => {
    setsingledata([data]);
    setviewmodalIsOpen(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = payoutdatalist.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(payoutdatalist.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const changeamount = async (value, id) => {
    setinputid(id);
    var updatepayout = {
      amount: value,
      id: id,
    };
    var checkdata = await payoutdatalist.filter((data) => {
      if (data.id == id) {
        data["amount"] = value;
      }
      return data;
    });
    setpayoutdatalist(checkdata);
    await updateDistributor_payout(updatepayout);
    // getpayoutdata();
  };
  const confirmbtn = async (data) => {
    if (data.user_id === null) {
      toast.info("Please Wait...", {
        autoClose: 2000,
        transition: Slide,
      });
      setclicked(true);
      var orderIdnew = `runpaisa${Math.random().toString(36).substring(2, 15)}`;
      var mode = document.getElementById(`mode${data.id}`).value;
      var tokendata = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: process.env.REACT_APP_USER_NAME,
        password: process.env.REACT_APP_PASSWORD,
      };
      var cretetoken = await CreteToken(tokendata);
      if (cretetoken.status === "SUCCESS") {
        var newdata = {
          beneficiaryName: data.name,
          orderId: orderIdnew,
          amount: data.amount,
          beneficiaryAccountNumber: data.account,
          beneficiaryIfscCode: data.ifsc,
          paymentMode: mode,
          callbackurl: "https://sampleurl.com",
          token: cretetoken.data.token,
          client_id: process.env.REACT_APP_CLIENT_ID,
          username: process.env.REACT_APP_USER_NAME,
          password: process.env.REACT_APP_PASSWORD,
        };
        var tranferdata = await transferPayout(newdata);
        if (tranferdata.status === "SUCCESS") {
          var statusdata = {
            orderId: orderIdnew,
            token: cretetoken.data.token,
            client_id: process.env.REACT_APP_CLIENT_ID,
          };
          var statuspayout = await statusPayout(statusdata);
          if (statuspayout.STATUS === "SUCCESS") {
            var updatedata = {
              orderid: statuspayout.ORDERSTATUS.ORDER_ID,
              date: statuspayout.ORDERSTATUS.PROCESSED_DATE,
              status: "SUCCESS",
              amount: statuspayout.ORDERSTATUS.TXN_AMOUNT,
              mode: statuspayout.ORDERSTATUS.TXN_MODE,
              urn_no: statuspayout.ORDERSTATUS.UTR_NO,
              Trans_date: statuspayout.ORDERSTATUS.TXN_DATE,
              user_id: null,
              opening_bal: null,
              closing_bal: null,
              name: data.name,
              account: data.account,
              ifsc: data.ifsc,
            };
            var updatepayout = {
              id: data.id,
              amount: "0",
            };
            await updateDistributor_payout(updatepayout);
            await CreteDistributor_transaction(updatedata);
          }
          toast.success("Payout Request accepted successfully...", {
            autoClose: 2000,
            transition: Slide,
          });
          getpayoutdata();
        } else {
          setclicked(false);
          toast.error(tranferdata.message, {
            autoClose: 2000,
            transition: Slide,
          });
          getpayoutdata();
        }
      } else {
        setclicked(false);
        toast.error(cretetoken.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    } else {
      setclicked(true);
      var userdata = await viewByidDistributor({ id: data.user_id });
      if (userdata.length !== 0) {
        if (Number(userdata[0].balance) >= Number(data.amount)) {
          toast.info("Please Wait...", {
            autoClose: 2000,
            transition: Slide,
          });
          var userid = sessionStorage.getItem("user_id");
          var orderId = `runpaisa${Math.random()
            .toString(36)
            .substring(2, 15)}`;
          var mode = document.getElementById(`mode${data.id}`).value;
          var tokendata = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            username: process.env.REACT_APP_USER_NAME,
            password: process.env.REACT_APP_PASSWORD,
          };
          var cretetoken = await CreteToken(tokendata);
          if (cretetoken.status === "SUCCESS") {
            var newdata = {
              beneficiaryName: data.name,
              orderId: orderId,
              amount: data.amount,
              beneficiaryAccountNumber: data.account,
              beneficiaryIfscCode: data.ifsc,
              paymentMode: mode,
              callbackurl: "https://sampleurl.com",
              token: cretetoken.data.token,
              client_id: process.env.REACT_APP_CLIENT_ID,
              username: process.env.REACT_APP_USER_NAME,
              password: process.env.REACT_APP_PASSWORD,
            };
            var tranferdata = await transferPayout(newdata);
            if (tranferdata.status === "SUCCESS") {
              var statusdata = {
                orderId: orderId,
                token: cretetoken.data.token,
                client_id: process.env.REACT_APP_CLIENT_ID,
              };
              setviewmodalIsOpen(false);
              var statuspayout = await statusPayout(statusdata);
              if (statuspayout.STATUS === "SUCCESS") {
                var useradata = await viewByidDistributor({ id: userid });
                var balance =
                  Number(useradata[0].balance) - Number(data.amount);
                var updatedata = {
                  orderid: statuspayout.ORDERSTATUS.ORDER_ID,
                  date: statuspayout.ORDERSTATUS.PROCESSED_DATE,
                  status: "SUCCESS",
                  amount: statuspayout.ORDERSTATUS.TXN_AMOUNT,
                  mode: statuspayout.ORDERSTATUS.TXN_MODE,
                  urn_no: statuspayout.ORDERSTATUS.UTR_NO,
                  Trans_date: statuspayout.ORDERSTATUS.TXN_DATE,
                  user_id: userid,
                  opening_bal: useradata[0].balance,
                  closing_bal: balance,
                  name: data.name,
                  account: data.account,
                  ifsc: data.ifsc,
                };
                var updatepayout = {
                  id: data.id,
                  amount: "0",
                };

                if (useradata.length !== 0) {
                  var newuserdata = {
                    id: userid,
                    balance:
                      Number(useradata[0].balance) -
                      Number(statuspayout.ORDERSTATUS.TXN_AMOUNT),
                  };
                  updateDistributordata(newuserdata);
                }
                var historydata = {
                  user_id: userid,
                  opening_bal: userdata[0].balance,
                  closing_bal:
                    Number(userdata[0].balance) - Number(data.amount),
                  amount: data.amount,
                  type: "payout",
                };
                await createHistory(historydata);
                await updateDistributor_payout(updatepayout);
                await CreteDistributor_transaction(updatedata);
                toast.success("Payout Request accepted successfully...", {
                  autoClose: 2000,
                  transition: Slide,
                });
                setviewmodalIsOpen(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                getpayoutdata();
              } else {
                setclicked(false);
                toast.error(statuspayout.message, {
                  autoClose: 2000,
                  transition: Slide,
                });
                setviewmodalIsOpen(false);
                getpayoutdata();
              }
            } else {
              setclicked(false);
              toast.error(tranferdata.message, {
                autoClose: 2000,
                transition: Slide,
              });
              setviewmodalIsOpen(false);
              getpayoutdata();
            }
          } else {
            setclicked(false);
            toast.error(cretetoken.message, {
              autoClose: 2000,
              transition: Slide,
            });
            setviewmodalIsOpen(false);
          }
        } else {
          setclicked(false);
          toast.error("Your Wallet Balance is low.", {
            autoClose: 2000,
            transition: Slide,
          });
          setviewmodalIsOpen(false);
        }
      }
    }
  };
  const editbtn = async (data) => {
    setIsOpen(true);
    setpayoutdata({
      account: data.account,
      amount: data.amount,
      ifsc: data.ifsc,
      name: data.name,
    });
    setupdatestatus(true);
    setupdateid(data.id);
  };
  const updatepayout = async () => {
    payoutdata["id"] = updateid;
    var updatedata = await updateDistributor_payout(payoutdata);
    if (updatedata === "Updated Successfully") {
      toast.success("Payout Updated Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setIsOpen(false);
      setupdatestatus(false);
      setupdateid(null);
      getpayoutdata();
    }
  };
  const deletebtn = async (data) => {
    var result = confirm("Do you want to delete this account?");
    if (result === true) {
      var deltebtn = await destroyDistributor_payout({ id: data.id });
      if (deltebtn === "Deleted Successfully") {
        toast.success("Account Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getpayoutdata();
      }
    }
  };
  const verifybtn = async () => {
    if (payoutdata.account.length === 0) {
      toast.error("Account Number Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (payoutdata.ifsc.length === 0) {
      toast.error("Ifsc Code Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var tokendata = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: process.env.REACT_APP_USER_NAME,
        password: process.env.REACT_APP_PASSWORD,
      };
      var cretetoken = await CreteToken(tokendata);
      if (cretetoken.status === "SUCCESS") {
        var newdata = {
          account: payoutdata.account,
          ifsc: payoutdata.ifsc,
          token: cretetoken.data.token,
          client_id: process.env.REACT_APP_CLIENT_ID,
        };

        var userid = sessionStorage.getItem("user_id");
        var viewuser = await viewByidDistributor({ id: userid });
        if (viewuser.length !== 0) {
          if (Number(viewuser[0].balance) >= 4.72) {
            var checkdata = await verifyPayout(newdata);
            if (checkdata.STATUS === "SUCCESS") {
              setpayoutdata({
                name: checkdata.BENEFICIARY_NAME,
                account: payoutdata.account,
                ifsc: payoutdata.ifsc,
              });
              var data = {
                balance: Number(viewuser[0].balance) - 4.72,
                id: viewuser[0].id,
              };
              updateDistributordata(data);
              setclicked(false);
            } else {
              setclicked(false);
              toast.error(checkdata.MESSAGE, {
                autoClose: 2000,
                transition: Slide,
              });
            }
          } else {
            toast.error("Your Wallet Balance is low.", {
              autoClose: 2000,
              transition: Slide,
            });
            setclicked(false);
          }
        }
      }
    }
  };
  const searchitem = async (e) => {
    if (e.length !== 0) {
      if (allpayoutlist.length !== 0) {
        var filterdata = [];
        for (var i = 0; i < allpayoutlist.length; i++) {
          if (allpayoutlist[i].name.toLowerCase().match(e.toLowerCase())) {
            filterdata.push(allpayoutlist[i]);
          }
        }
        setpayoutdatalist(filterdata);
      }
    } else {
      window.location.reload();
      // setpayoutdatalist(allpayoutlist);
    }
  };
  return (
    <div>
      <h1 className="text-start font-bold text-xl mt-5">Payouts List</h1>
      <div className="flex justify-between py-10">
        <div className="flex justify-between items-center p-2 w-3/6 bg-slate-200 rounded-sm border-2 ">
          <input
            placeholder="Search your requirement "
            className="border-none outline-none bg-slate-200 px-2 py-1"
            onChange={(e) => {
              searchitem(e.target.value);
            }}
          />
          <AiOutlineSearch className="text-white w-6 h-6" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <table className="w-full text-center items-center  bg-white shadow shadow-gray-500/40 hover:shadow-gray-500/20">
          <tr className="bg-black text-white text-sm border border-gray-200 ">
            <th className="px-10 py-3">#</th>
            <th className="px-10 py-3">Action</th>
            <th className="px-10 py-3">
              <p>Name</p>
            </th>
            <th className="px-10 py-3">
              <p>Account Number</p>
            </th>
            <th className="px-10 py-3">
              <p>IFSC</p>
            </th>
            <th className="px-10 py-3">
              <p>Mode</p>
            </th>
            <th className="px-10 py-3">
              <p>Amount</p>
            </th>
            <th className="px-10 py-3">
              <p>Transfer</p>
            </th>
            {/* <th className="px-10 py-3">
              <p>Action</p>
            </th> */}
          </tr>
          {currentPost.length !== 0
            ? currentPost.map((data, index) => (
                <tr className="border border-black py-3" key={index}>
                  <td className="py-2 border border-black">{index + 1}</td>
                  <td className="py-2 border border-black">
                    <div className="flex gap-2 justify-center">
                      <button
                        className="bg-blue-500 text-white font-bold rounded rounded text-sm px-3 py-2"
                        onClick={() => {
                          editbtn(data);
                        }}
                      >
                        <BsPencilSquare />
                      </button>
                      <button
                        className="bg-blue-800 text-white font-bold rounded rounded text-sm px-3 py-2"
                        onClick={() => {
                          deletebtn(data);
                        }}
                      >
                        <BsFillTrash2Fill />
                      </button>
                    </div>
                  </td>
                  <td className="py-2 border border-black"> {data.name}</td>
                  <td className="py-2 border border-black">{data.account}</td>
                  <td className="py-2 border border-black">{data.ifsc}</td>
                  <td className="py-2 border border-black">
                    <select
                      className="border w-28 outline-none px-5 py-1 rounded-sm bg-gradient-to-r from-zinc-500 to-white"
                      id={`mode${data.id}`}
                    >
                      <option value="IMPS">IMPS</option>
                      <option value="NEFT">NEFT</option>
                    </select>
                  </td>
                  <td className="py-2 border border-black">
                    {inputid == data.id ? (
                      <input
                        type="number"
                        defaultValue={data.amount}
                        className="border w-20 border-black rounded outline-none py-1 rounded-sm from-zinc-500 to-white text-center"
                        onChange={(e) => {
                          changeamount(e.target.value, data.id);
                        }}
                      />
                    ) : (
                      <input
                        type="number"
                        value={data.amount}
                        className="border w-20 border-black rounded outline-none py-1 rounded-sm from-zinc-500 to-white text-center"
                        onChange={(e) => {
                          changeamount(e.target.value, data.id);
                        }}
                      />
                    )}
                    {data.amount == 0 ? null : (
                      <p className="text-blue-400 text-xs mt-2 capitalize font-semibold">
                        {NumInWords(Number(data.amount))} only/-
                      </p>
                    )}
                  </td>
                  <td>
                    <button
                      className="bg-green-500 text-white font-bold rounded rounded text-sm px-3 py-2"
                      onClick={() => {
                        transferbtn(data);
                      }}
                    >
                      Transfer
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </table>
      </div>
      {pageNumbers.length > 1 ? (
        <div className="row mt-5">
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
              >
                <span
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="itemmodel "
      >
        <div className="flex justify-between text-lg py-2">
          {updatestatus === false ? (
            <h2 className="font-medium text-md ">Add New Payout</h2>
          ) : (
            <h2 className="font-medium text-md ">Update Payout</h2>
          )}
          <button onClick={closeModal}>
            <IoMdClose className="w-6 h-6" />
          </button>
        </div>
        <div className="w-full py-1 ">
          <label className="my-1">Account Number</label>
          <br />
          <input
            className="border-2 w-full p-2 outline-none bg-zinc-200 rounded-sm "
            placeholder="Enter the account number"
            name="account"
            defaultValue={payoutdata.account}
            onChange={handleChange}
          />
        </div>
        <div className="w-full py-1 ">
          <label className="my-1">IFSC</label>
          <br />
          <input
            className="border-2 w-full p-2 outline-none bg-zinc-200 rounded-sm "
            placeholder="Enter the IFSC"
            defaultValue={payoutdata.ifsc}
            name="ifsc"
            onChange={handleChange}
          />
        </div>
        {clicked == false ? (
          <button
            className="bg-green-600 text-white font-sm font-semibold p-1 w-[5rem] rounded my-2"
            onClick={verifybtn}
          >
            Verify
          </button>
        ) : (
          <button className="bg-green-600 text-white font-sm font-semibold p-1 w-[5rem] rounded my-2">
            Wait...
          </button>
        )}
        <div className="w-full py-1 ">
          <label className="my-1">Account Holder Name</label>
          <br />
          <input
            className="border-2 w-full p-2 outline-none bg-zinc-200 rounded-sm "
            placeholder="Enter the Account Holder Name"
            name="name"
            defaultValue={payoutdata.name}
            onChange={handleChange}
          />
        </div>
        {/* <div className="w-full py-1 ">
          <label className="my-1">Amount</label>
          <br />
          <input
            className="border-2 w-full p-2 outline-none bg-zinc-200 rounded-sm "
            placeholder="Enter the Amount"
            name="amount"
            // onChange={handleChange}
          />
        </div> */}
        <div className="flex justify-between mt-5 w-full">
          {updatestatus === false ? (
            <button
              className="bg-blue-500 w-64 p-1 mr-1 rounded text-white font-semibold"
              onClick={addpayout}
            >
              Add Payout
            </button>
          ) : (
            <button
              className="bg-blue-500 w-64 p-1 mr-1 rounded text-white font-semibold"
              onClick={updatepayout}
            >
              Update Payout
            </button>
          )}
          <button
            className="bg-white border w-64 border-gray-600 font-semibold p-1 rounded text-black"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={viewmodalIsOpen}
        onRequestClose={closeModalnew}
        className="itemmodelnew"
      >
        <div className="flex justify-between text-lg py-2">
          <h2 className="font-medium text-md ">Confirm Transaction</h2>
          <button onClick={closeModalnew}>
            <IoMdClose className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="flex justify-around py-5">
          <div>
            <h1 className="font-semibold mt-4">Amount</h1>
            <h1 className="font-semibold mt-4">Send To</h1>
            <h1 className="font-semibold mt-4">Account Number</h1>
            <h1 className="font-semibold mt-4">IFSC</h1>
            <h1 className="font-semibold mt-4">Transfer Type</h1>
          </div>
          {singledata.length !== 0 ? (
            <div>
              <h1 className="font-semibold mt-4">: {singledata[0].amount}</h1>
              <h1 className="font-semibold mt-4">: {singledata[0].name}</h1>
              <h1 className="font-semibold mt-4">: {singledata[0].account}</h1>
              <h1 className="font-semibold mt-4">: {singledata[0].ifsc}</h1>
              <h1 className="font-semibold mt-4">
                : {document.getElementById(`mode${singledata[0].id}`).value}
              </h1>
            </div>
          ) : null}
        </div>
        <hr />
        <div className="flex justify-end mt-5 w-full">
          {clicked == false ? (
            <button
              className="bg-blue-500 w-[7rem] p-2 mr-1 rounded text-white font-semibold text-sm"
              onClick={() => {
                confirmbtn(singledata[0]);
              }}
            >
              CONFIRM
            </button>
          ) : (
            <button className="bg-blue-500 w-[7rem] p-2 mr-1 rounded text-white font-semibold text-sm">
              CONFIRM
            </button>
          )}
          <button
            className="bg-red-600 border w-[7rem] border-red-600 font-semibold p-2 rounded text-white text-sm"
            onClick={closeModalnew}
          >
            CLOSE
          </button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Dis_payout;
