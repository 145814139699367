import axios from "axios";

export const User = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/user`,
});

export const Admin = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/admin`,
});

export const Paymentlink = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/paymentlink`,
});

export const Order = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/order`,
});

export const Receptionist = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/receptionist`,
});

export const Payout = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/payout`,
});

export const Transaction = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/transaction`,
});

export const Paymenthistory = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/paymenthistory`,
});

export const Smtp = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/smtp`,
});

export const History = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/history`,
});

export const Distributor = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor`,
});

export const Distributor_payout = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor_payout`,
});

export const Distributor_transaction = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor_transaction`,
});

export const Walletcredit = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/walletcredit`,
});

export const Walletdebit = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/walletdebit`,
});

export const Pgtransfer = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/pgtransfer`,
});
