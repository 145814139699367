import React, { useEffect, useState } from "react";
import { viewAllPaymentlink } from "../../Api/Paymentlink";
import { Alluserdata } from "../../Api/User";
import { viewAllTransaction } from "../../Api/transaction";
import { viewAllPayout } from "../../Api/payout";

const Dashboard = () => {
  const [totalsum, settotalsum] = useState(0);
  const [pendingsum, setpendingsum] = useState(0);
  const [successsum, setsuccesssum] = useState(0);
  const [usercount, setusercount] = useState(0);
  const [payoutcount, setpayoutcount] = useState(0);
  const [transactioncount, settransactioncount] = useState(0);
  useEffect(() => {
    getmypaymentlink();
  }, []);
  const getmypaymentlink = async () => {
    var allmydata = await viewAllPaymentlink();
    if (allmydata.length !== 0) {
      var total = [],
        pending = [],
        success = [];
      for (var i = 0; i < allmydata.length; i++) {
        if (allmydata[i].status === "Success") {
          success.push(Number(allmydata[i].amount));
        }
        if (allmydata[i].status === "Pending") {
          pending.push(Number(allmydata[i].amount));
        }
        total.push(Number(allmydata[i].amount));
      }
      const totalsum = total.reduce((partialSum, a) => partialSum + a, 0);
      const pendingsum = pending.reduce((partialSum, a) => partialSum + a, 0);
      const successsum = success.reduce((partialSum, a) => partialSum + a, 0);
      settotalsum(totalsum);
      setpendingsum(pendingsum);
      setsuccesssum(successsum);
    }
    var userdata = await Alluserdata();
    var alldata = await viewAllTransaction();
    var allpayout = await viewAllPayout();
    if (allpayout.length !== 0) {
      var filterdata = await allpayout.filter((data) => {
        return data.status === "Pending";
      });
      setpayoutcount(filterdata.length);
    }
    setusercount(userdata.length);
    settransactioncount(alldata.length);
  };
  return (
    <div>
      <h1 className="text-start font-bold text-xl mt-5">Dashboard</h1>
      <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-3 md:gap-4 mt-12">
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">Pending</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(pendingsum).toFixed(2)}
          </p>
        </div>
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">Success</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(successsum).toFixed(2)}
          </p>
        </div>
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">Total</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(totalsum).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-3 md:gap-4 mt-12">
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          onClick={() => {
            window.location.replace("/user");
          }}
        >
          <p className="text-center text-black text-xl">Total Users</p>
          <p className="text-center text-red-500 text-3xl">{usercount}</p>
        </div>
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          onClick={() => {
            window.location.replace("/Payout");
          }}
        >
          <p className="text-center text-black text-xl">Total Payout</p>
          <p className="text-center text-red-500 text-3xl">{payoutcount}</p>
        </div>
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          onClick={() => {
            window.location.replace("/transaction");
          }}
        >
          <p className="text-center text-black text-xl">Total Transactions</p>
          <p className="text-center text-red-500 text-3xl">
            {transactioncount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
