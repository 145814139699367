import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Dropdown from "./Dropdown";
import { viewByidUser } from "../Api/Admin";
import { CreteToken, getbalanceOrder } from "../Api/order";
const Header = () => {
  const [profiledata, setprofiledata] = useState([]);
  const [balancevalue, setbalancevalue] = useState(0);
  useEffect(() => {
    getprofile();
  }, []);
  const getprofile = async () => {
    var userid = sessionStorage.getItem("user_id");
    var viewuser = await viewByidUser({ id: userid });
    if (viewuser.length !== 0) {
      setprofiledata(viewuser);
    }
    var data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      username: process.env.REACT_APP_USER_NAME,
      password: process.env.REACT_APP_PASSWORD,
    };
    var cretetoken = await CreteToken(data);
    if (cretetoken.status === "SUCCESS") {
      var newdata = {
        token: cretetoken.data.token,
        client_id: process.env.REACT_APP_CLIENT_ID,
      };
      var getbalance = await getbalanceOrder(newdata);
      if (getbalance.message === "Balance fetched Successfully") {
        setbalancevalue(getbalance.balance.T_BAL);
      }
    }
  };
  return (
    <header className="w-full py-8 px-8 h-12 flex items-center justify-between bg-gradient-to-r from-sky-500 to-indigo-500">
      <h1 className="text-2xl text-white font-medium">Admin Panel</h1>
      {profiledata.length !== 0 ? (
        <div className="flex justify-end">
          <div className="flex flex-col justify-end">
            <h3 className="text-white text-lg mx-4">
              Hello <b>{profiledata[0].name}</b>
            </h3>
            <p className="text-white text-xs mx-4 text-start">
              Balance: ₹ {parseFloat(balancevalue).toFixed(2)}
            </p>
          </div>
          <Avatar name={`${profiledata[0].name}`} size={40} round="20px" />
          <Dropdown />
        </div>
      ) : null}
    </header>
  );
};

export default Header;
