import React, { useState } from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import { CreteToken, CreteOrder } from "../Api/order";
import { CretePaymentlink } from "../Api/Paymentlink";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Payment = () => {
  const [inputs, setInputs] = useState({
    amount: "",
    phone: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [paymentlink, setpaymentlink] = useState(null);
  const [paymentbtn, setpaymentbtn] = useState(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitbtn = async () => {
    const { amount, phone } = inputs;
    const newerrors = {};
    if (amount.length === 0) {
      newerrors.amount = "Please Enter Amount...";
    }
    seterrorlist(newerrors);
    if (Object.keys(newerrors).length === 0) {
      var data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: process.env.REACT_APP_USER_NAME,
        password: process.env.REACT_APP_PASSWORD,
      };
      var cretetoken = await CreteToken(data);
      if (cretetoken.status === "SUCCESS") {
        var creteorder = await CreteOrder({
          amount: amount,
          order_id: `mespay${Math.random().toString(36).substring(2, 15)}`,
          token: cretetoken.data.token,
        });
        if (creteorder.status === "SUCCESS") {
          setpaymentlink(creteorder.paymentLink);
          var newdata = {
            amount: amount,
            phone: phone,
            payment_link: creteorder.paymentLink,
            status: "Pending",
            orderid: creteorder.order_id,
          };
          var createdata = await CretePaymentlink(newdata);
          if (createdata.message === "SUCCESS") {
            setpaymentbtn(false);
            toast.success("Payment Link Generated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
          }
        }
      }
    }
  };
  const copyclipboard = async () => {
    navigator.clipboard.writeText(paymentlink);
    alert("Payment link Copied...");
  };

  return (
    <>
      <h1 className="text-start font-bold text-xl mt-5">Create Payment Link</h1>
      <div className="w-full px-10 py-10">
        <div className="shadow-lg shadow-gray-400/100  w-3/6 bg-white flex flex-col px-5 py-5 rounded-md">
          <label className="mt-5 text-left py-1 font-bold">Amount</label>
          <div>
            <input
              className="border border-black p-2 rounded-lg outline-none w-full"
              placeholder="Enter the amount"
              alt=""
              name="amount"
              onChange={handleChange}
            />
            {errorlist !== null ? (
              <div className="text-red-500 text-start">{errorlist.amount}</div>
            ) : null}
          </div>
          <div className="flex justify-between mt-5 px-1">
            <label className="py-1 font-bold">Customer Mobile Number</label>
            <label className="py-1 text-blue-500">(optional)</label>
          </div>
          <div>
            <input
              className="border border-black p-2 rounded-lg outline-none w-full"
              placeholder="Enter Customer Mobile Number"
              alt=""
              name="phone"
              onChange={handleChange}
            />
          </div>
          <div className="w-full my-2">
            {paymentbtn === true ? (
              <button
                className="bg-[#007DFA] font-semibold my-4 w-full h-10 rounded text-white"
                onClick={submitbtn}
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-green-600 font-semibold my-4 w-full h-10 rounded text-white"
                // onClick={submitbtn}
              >
                Submitted
              </button>
            )}
          </div>
        </div>
        {paymentlink !== null ? (
          <div className=" shadow-lg shadow-gray-400/100 rounded-md   bg-white  px-5  my-6">
            <p className="text-blue-500 text-left py-1 text-lg ">
              Payment Link
            </p>
            <div className="flex justify-between pt-2 pb-4">
              <input
                type="url"
                placeholder="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEyIWiK8-Yz0mpAJaCzBcwRClyrtbUdM04lA&usqp=CAU"
                className="bg-slate-200 w-full mx-1 px-1 py-2 border-2 border-slate-200 rounded-sm outline-none "
                value={paymentlink}
                disabled
              />
              <div className="flex justify-evenly">
                <div
                  className="bg-green-400 w-10 h-10 flex justify-center items-center rounded-sm mx-1 cursor-pointer"
                  onClick={copyclipboard}
                >
                  <AiOutlineWhatsApp className="text-white w-6 h-6" />
                </div>
                <div
                  className="w-10 h-10 flex justify-center items-center border-2 border-black rounded-sm mx-1 cursor-pointer"
                  onClick={copyclipboard}
                >
                  <BsLink45Deg className="text-black w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
};

export default Payment;
