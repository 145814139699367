import { Walletcredit } from "../axios";

export const CreteWalletcredit = async (data) => {
  var CreteWalletcredit = await Walletcredit.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteWalletcredit;
};

export const viewByUserWalletcredit = async (data) => {
  var viewByUserWalletcredit = await Walletcredit.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserWalletcredit;
};

export const viewByDateWalletcredit = async (data) => {
  var viewByDateWalletcredit = await Walletcredit.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateWalletcredit;
};

export const viewAllWalletcredit = async (data) => {
  var viewAllWalletcredit = await Walletcredit.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllWalletcredit;
};
