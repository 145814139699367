import { History } from "../axios";

export const createHistory = async (data) => {
  var createHistory = await History.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createHistory;
};
