import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";

import "../App.css";
const Dropdown = () => {
  const logoutbtn = () => {
    sessionStorage.removeItem("user_id");
    window.location.reload();
  };
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col text-sm  ">
      <div className=" w-32 relative">
        <BiChevronDown
          className="w-8 h-8 text-white mt-1"
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div class="absolute pt-1 -ml-10 top-12">
            <ul className="border py-2 border-gray-200 rounded-sm shadow-lg shadow-gray-400/2 scale-100">
              <li
                className="py-1 pr-10 pl-3 text-left cursor-pointer"
                onClick={logoutbtn}
              >
                Log out
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
