/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./App.css";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import { updatePaymentlink, viewAllPaymentlink } from "./Api/Paymentlink";
import { CreteToken, StatusOrder } from "./Api/order";
import {
  checkByUserPaymenthistory,
  destroyPaymenthistory,
} from "./Api/paymenthistory";
import { updateuserdata, viewByidUser } from "./Api/User";
import { createHistory } from "./Api/History";
import { updateDistributordata, viewByidDistributor } from "./Api/distributor";
function App() {
  var [userid, setuserid] = useState(null);
  useEffect(() => {
    var userid = sessionStorage.getItem("user_id");
    setuserid(userid);
    //   getalldata();
  }, [userid]);
  // const getalldata = async () => {
  //   var allmydata = await viewAllPaymentlink();
  //   if (allmydata.length !== 0) {
  //     var data = {
  //       client_id: process.env.REACT_APP_CLIENT_ID,
  //       username: process.env.REACT_APP_USER_NAME,
  //       password: process.env.REACT_APP_PASSWORD,
  //     };
  //     var cretetoken = await CreteToken(data);
  //     for (var i = 0; i < allmydata.length; i++) {
  //       if (allmydata[i].status === "Pending") {
  //         var datanew = {
  //           orderid: allmydata[i].orderid,
  //           client_id: process.env.REACT_APP_CLIENT_ID,
  //           token: cretetoken.data.token,
  //         };
  //         var statusorder = await StatusOrder(datanew);

  //         if (
  //           statusorder.ORDERSTATUS !== null &&
  //           statusorder.ORDERSTATUS !== undefined
  //         ) {
  //           if (statusorder.ORDERSTATUS.STATUS === "SUCCESS") {
  //             var checkuser = await checkByUserPaymenthistory({
  //               id: allmydata[i].user_id,
  //               orderid: statusorder.ORDERSTATUS.ORDER_ID,
  //             });
  //             var userdata = await viewByidUser({ id: allmydata[i].user_id });
  //             if (checkuser.length !== 0) {
  //               var newdataupdate = {
  //                 id: allmydata[i].user_id,
  //                 balance:
  //                   Number(userdata[0].balance) + Number(checkuser[0].amount),
  //               };
  //               var historydata = {
  //                 user_id: allmydata[i].user_id,
  //                 opening_bal: userdata[0].balance,
  //                 closing_bal:
  //                   Number(userdata[0].balance) + Number(checkuser[0].amount),
  //                 amount: checkuser[0].amount,
  //                 type: "paymentlink",
  //               };
  //               if (userdata[0].dis_id !== null) {
  //                 var viewdistributor = await viewByidDistributor({
  //                   id: userdata[0].dis_id,
  //                 });
  //                 var newdistributordata = {
  //                   id: viewdistributor[0].id,
  //                   balance:
  //                     Number(viewdistributor[0].balance) +
  //                     Number(checkuser[0].distributoramount),
  //                 };
  //                 updateDistributordata(newdistributordata);
  //               }
  //               await createHistory(historydata);
  //               await updateuserdata(newdataupdate);
  //               // await destroyPaymenthistory({ id: checkuser[0].id });
  //             }

  //             var updatedata = {
  //               id: allmydata[i].id,
  //               status: "Success",
  //             };
  //             await updatePaymentlink(updatedata);
  //           }
  //         }
  //       }
  //     }
  //     getalldata();
  //   }
  // };
  return (
    <>
      {userid === null ? <Login /> : <Layout />}
      {/* <Layout /> */}
    </>
  );
}

export default App;
