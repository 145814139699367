import { Walletdebit } from "../axios";

export const CreteWalletdebit = async (data) => {
  var CreteWalletdebit = await Walletdebit.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteWalletdebit;
};

export const viewByUserWalletdebit = async (data) => {
  var viewByUserWalletdebit = await Walletdebit.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserWalletdebit;
};

export const viewByDateWalletdebit = async (data) => {
  var viewByDateWalletdebit = await Walletdebit.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateWalletdebit;
};

export const viewAllWalletdebit = async (data) => {
  var viewAllWalletdebit = await Walletdebit.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllWalletdebit;
};
