import { Paymentlink } from "../axios";

export const CretePaymentlink = async (data) => {
  var CretePaymentlink = await Paymentlink.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePaymentlink;
};

export const updatePaymentlink = async (data) => {
  var updatePaymentlink = await Paymentlink.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatePaymentlink;
};

export const viewByDatePaymentlink = async (data) => {
  var viewByDatePaymentlink = await Paymentlink.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDatePaymentlink;
};

export const viewAllPaymentlink = async (data) => {
  var viewAllPaymentlink = await Paymentlink.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllPaymentlink;
};
