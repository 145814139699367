/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Alluserdata } from "../Api/User";
import { viewAllWalletdebit, viewByDateWalletdebit } from "../Api/walletdebit";

const Wallet_Debit_Trans = () => {
  const [inputs, setInputs] = useState({
    from: "",
    to: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [alltransactiondata, setalltransactiondata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [userdata, setuserdata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const filterbtn = async () => {
    const newerrors = {};
    if (inputs.from.length === 0) {
      newerrors.from = "Please Select From date...";
    }
    if (inputs.to.length === 0) {
      newerrors.to = "Please Select To date...";
    }
    seterrorlist(newerrors);
    if (Object.keys(newerrors).length === 0) {
      var userid = sessionStorage.getItem("user_id");
      inputs["id"] = userid;
      var alldata = await viewByDateWalletdebit(inputs);
      if (alldata.length !== 0) {
        var checkdata = await alldata.filter((data) => {
          return data.user_id !== null;
        });
        setalltransactiondata(checkdata);
        settotaldata(checkdata);
      } else {
        setalltransactiondata([]);
        settotaldata([]);
      }
    }
  };
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alldata = await viewAllWalletdebit();
    if (alldata.length !== 0) {
      var checkdata = await alldata.filter((data) => {
        return data.user_id !== null;
      });
      setalltransactiondata(checkdata);
      settotaldata(checkdata);
    } else {
      setalltransactiondata([]);
      settotaldata([]);
    }
    var userdata = await Alluserdata();
    setuserdata(userdata);
  };
  const clearbtn = async () => {
    getalldata();
    setInputs((values) => ({ ...values, from: "" }));
    setInputs((values) => ({ ...values, to: "" }));
    document.getElementById("from").value = "";
    document.getElementById("to").value = "";
  };
  const searchitem = async (e) => {
    if (e !== "null") {
      if (totaldata.length !== 0) {
        var checkuser = await totaldata.filter((data) => {
          return data.User.id == e;
        });
        setalltransactiondata(checkuser);
      }
    } else {
      setalltransactiondata(totaldata);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(30);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = alltransactiondata.slice(
    indexofFirstPost,
    indexofLastPost
  );
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(alltransactiondata.length / postsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  return (
    <div>
      <h1 className="text-start font-bold text-xl mt-5">
        Wallet Debit Transaction List
      </h1>
      <div className="bg-white flex flex-col px-5 py-5">
        <label className="text-left py-1 font-bold">From</label>
        <div>
          <div className="flex rounded border border-black justify-between items-center px-4 w-72">
            <input
              className="p-2 outline-none w-full"
              placeholder=""
              alt=""
              type="date"
              name="from"
              id="from"
              onChange={handleChange}
            />
          </div>
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.from}</div>
          ) : null}
        </div>
        <div className="flex mt-2 px-1">
          <label className="py-1 font-bold">To</label>
        </div>
        <div className="flex rounded border border-black justify-between items-center px-4  w-72">
          <input
            className="p-2 outline-none w-full"
            placeholder=""
            alt=""
            type="date"
            id="to"
            name="to"
            onChange={handleChange}
          />
        </div>
        {errorlist !== null ? (
          <div className="text-red-500 text-start">{errorlist.to}</div>
        ) : null}
        <div className="w-full my-2 text-start">
          <button
            className="bg-[#007DFA] my-4 text-sm font-bold w-32 h-10 rounded text-white mr-5"
            onClick={filterbtn}
          >
            Filter
          </button>
          <button
            className="bg-[red] mt-2 text-sm font-bold w-32 h-10 rounded text-white"
            onClick={clearbtn}
          >
            Clear
          </button>
        </div>

        <label variant="subtitle2" className="text-left py-1 font-bold">
          Select Retailer
        </label>
        <select
          className="border p-2 border-black rounded w-1/3"
          onChange={(e) => {
            searchitem(e.target.value);
          }}
        >
          <option className="uppercase font-bold" value="null">
            Select
          </option>
          {userdata.length !== 0
            ? userdata.map((data, index) => (
                <option
                  key={index}
                  className="uppercase font-bold"
                  value={data.id}
                >
                  {data.name}
                </option>
              ))
            : null}
        </select>
      </div>
      <div className="mr-5 w-full px-5 py-5">
        <table className="w-full border border-black rounded-md bg-white hover:shadow-gray-500/20">
          <tr className="bg-black text-white text-sm font-light w-full">
            <th className="px-6=2 py-2">#</th>
            <th className="px-2 py-2">
              <p>Retailer Name</p>
            </th>
            <th className="px-2 py-2">
              <p>Date</p>
            </th>
            <th className="px-2 py-2">
              <p>Opening Balance </p>
            </th>
            <th className="px-6=2 py-2">
              <p>Transfer Amount</p>
            </th>
            <th className="px-2 py-2">
              <p>Closing Balance</p>
            </th>
          </tr>
          {currentPost.length !== 0
            ? currentPost.map((data, index) => (
                <tr className="py-3 border border-black" key={index}>
                  <td className="py-2 px-2 font-semibold border border-black">
                    {index + 1}
                  </td>
                  {data.User !== null ? (
                    <td className="py-2 font-semibold border border-black text-sm">
                      {data.User.name}
                    </td>
                  ) : (
                    <td className="py-2 font-semibold border border-black text-sm">
                      -
                    </td>
                  )}
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.date}
                  </td>

                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.opening_bal}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.amount}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.closing_bal}
                  </td>
                </tr>
              ))
            : null}
        </table>
        {pageNumbers.length > 1 ? (
          <div className="row mt-5">
            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={() => setCurrentPage(number)}
                    className="page-link"
                  >
                    {number}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Wallet_Debit_Trans;
