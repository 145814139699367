import { Transaction } from "../axios";

export const CreteTransaction = async (data) => {
  var CreteTransaction = await Transaction.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteTransaction;
};

export const viewByUserTransaction = async (data) => {
  var viewByUserTransaction = await Transaction.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserTransaction;
};

export const viewByDateTransaction = async (data) => {
  var viewByDateTransaction = await Transaction.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateTransaction;
};

export const viewAllTransaction = async (data) => {
  var viewAllTransaction = await Transaction.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllTransaction;
};

export const viewByorderidTransaction = async (data) => {
  var viewByorderidTransaction = await Transaction.post(`/viewByorderid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByorderidTransaction;
};

export const updateTransaction = async (data) => {
  var updateTransaction = await Transaction.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateTransaction;
};
