import { Smtp } from "../axios";

export const Registersuccess = async (data) => {
  var Registersuccess = await Smtp.post(`/registersuccess`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Registersuccess;
};
